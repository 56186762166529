.headerBarPrimary{
    min-height: 300px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pomodoroDescription {
    margin-block-start: 0;
    margin-block-end: 0;
    background-color: #464646;
    padding-left: 20px;
    padding-top: 50px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}